<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                  <li class="breadcrumb-item active">Asignar Servicios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="title">Postulación de Servicios</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-md-center">
                  <div id="accordion" class="col-md-4">
                    <div class="card text-muted text-xs">
                      <div class="card-header bg-frontera">
                        <h4 class="card-title">
                          <a class="d-block text-white" data-toggle="collapse" href="#collapseOne">
                            Detalle Solicitud - {{solicitud.id}}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="collapse" data-parent="#accordion">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12">
                              <ul class="ml-4 mb-0 fa-ul">
                                <li>
                                  <span class="fa-li">
                                    <i class="fas fa-lg fa-info"></i>
                                  </span>
                                  <strong>Detalle</strong>
                                  <div class="row">
                                    <div class="col-md-5">Tipo Solicitud:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{solicitud.nTipoSolicitud}}</strong>
                                    </div>
                                  </div>
                                  <div class="row"
                                    v-if="solicitud.tipo_emergencia"
                                  >
                                    <div class="col-md-5">Tipo Emergencia:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{solicitud.nTipoEmergencia}}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Solicitante:</div>
                                    <div class="col-md-7 mb-1">
                                      <strong>{{solicitud.user ? solicitud.user.name : ""}}</strong>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Material:</div>
                                    <div class="col-md-7 mb-1">
                                      <i 
                                        class="fa"
                                        :class="solicitud.material_inventarios ? 'fa-check text-success' : 'fa-times-circle text-danger'"
                                      ></i>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Presupuesto Verificado:</div>
                                    <div class="col-md-7 mb-1">
                                      <i 
                                        class="fa"
                                        :class="solicitud.presupuesto_verificado ? 'fa-check text-success' : 'fa-times-circle text-danger'"
                                      ></i>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Estado:</div>
                                    <div class="col-md-7 mb-1">
                                      <span class="badge"
                                        :class="
                                          solicitud.estado == 1 ? 'bg-warning' :
                                          solicitud.estado == 2 ? 'bg-success' : 
                                          'bg-secondary'
                                        " 
                                      >
                                        {{ solicitud.nEstado }}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Justificación:</div>
                                    <div class="col-md-7 mb-1">
                                      <small>{{solicitud.justificacion}}</small>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-5">Observación:</div>
                                    <div class="col-md-7 mb-1">
                                      <small>{{solicitud.observaciones}}</small>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table_responsive p-0">
                <table class="table table-bordered table-striped table-hover table-sm text-nowrap">
                  <thead>
                    <tr>
                      <th>Empresa</th>
                      <th>Tipo Servico</th>
                      <th>Tipo Vehículo/Equipo</th>
                      <th>Cantidad</th>
                      <th>Tiempo <small>(días)</small></th>
                      <th>Estado</th>
                      <th style="width: 8em;">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tip_serv in tipos_servicios"
                      :key="tip_serv.id"
                    >
                      <td>{{tip_serv.empresa ? tip_serv.empresa.razon_social : ""}}</td>
                      <td class="text-center">
                        <span class="badge"
                          :class="
                            tip_serv.tipo_servicio == 1 ? 'bg-info' :
                            tip_serv.tipo_servicio == 2 ? 'bg-navy' : 
                            'bg-secondary'
                          " 
                        >
                          {{ tip_serv.nTipoServicio }}
                        </span>
                      </td>
                      <td>{{tip_serv.tipo_servicio == 1 ? tip_serv.nTipoVh : tip_serv.nTipoEqui}}</td>
                      <td class="text-center">{{tip_serv.cantidad}}</td>
                      <td class="text-center">{{tip_serv.tiempo}}</td>
                      <td class="text-center">
                        <span class="badge"
                          :class="
                            tip_serv.postulacion ? 'bg-success' :
                            'bg-secondary'
                          " 
                        >
                          {{ tip_serv.postulacion ? "Postulado" : "Sin postular" }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button type="button" class="btn btn-sm bg-primary"
                            data-toggle="modal"
                            data-target="#modal-form"
                            v-if="
                              $store.getters.can('cs.nacionalesPostulaciones.show') &&
                              tip_serv.postulacion
                            "
                            @click="$refs.PostulacionAsignacion.init(tip_serv.id, 2)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            class="btn btn-sm bg-danger"
                            v-if="$store.getters.can('cs.nacionalesPostulaciones.delete') && tip_serv.postulacion && accion == 1"
                            @click="destroy(tip_serv.postulacion.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                          <button type="button" class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form"
                            v-if="$store.getters.can('cs.nacionalesPostulaciones.edit') && accion == 1"
                            @click="$refs.PostulacionAsignacion.init(tip_serv.id, 1)"
                            :disabled="tip_serv.estado != 1"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PostulacionAsignacion ref="PostulacionAsignacion"></PostulacionAsignacion>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import PostulacionAsignacion from "./PostulacionAsignacion";

export default {
  name: "PostulacionForm",
  components: {
    Loading,
    PostulacionAsignacion,
  },
  data() {
    return {
      cargando: false,
      accion: 2,
      tipos_servicios: [],
      solicitud: [],
    };
  },

  methods: {
    init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.cs_solicitud_nacional_id = this.$route.params.solicitud.id;

      axios
        .get("/api/cs/postulacionesNacionales/show/" + this.cs_solicitud_nacional_id)
        .then((response) => {
          this.cargando = false;
          this.tipos_servicios = response.data;
          if (this.tipos_servicios) {
            this.solicitud = this.tipos_servicios[0].solicitud;
          }
        })
        .catch(function(error) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    /***** Funciones para los botones de los formularios */
    destroy(id = null) {
      this.$swal({
        title: "Está seguro de eliminar esta postulación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          await axios
            .delete("/api/cs/postulacionesNacionales/" + id)
            .then(() => {
              this.cargando = false;
              this.init();
              this.$swal({
                icon: "success",
                title: "Se eliminó la postulación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });

        }
      });
    },

    back() {
      return this.$router.replace("/Cs/PostulacionesNacionales");
    },
  },

  mounted() {
    this.init();
  },
};
</script>
